import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import i18next from "../i18n.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Box, Icon, Button, Link, Chip } from "@material-ui/core";
import {
  getOffer,
  getOfferPreview,
  enrollOffer,
  getKQ,
  getCompetences,
  isCandidateEnrollInOffer,
} from "../api/oferta";
import Location from "../assets/icons/location";
import TimeClock from "../assets/icons/timeClock";
import Sign from "../assets/icons/sign";
import Layout from "../components/basicLayout";
import SocialIcon from "../assets/icons/socialIcons";
import Snackbar from "../components/customSnackBar";
import { withSnackbar } from "notistack";
import {
  getContractTypes,
  getProvincias,
  returnLangId,
  getMinimumExperience,
  getSalaryRange,
  getSalaryType,
  handleGetMaestro,
         getNivelesEstudio
} from "../utils/functions";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import AutoevalSlider from "../components/autoevalSlider";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import ButtonOutlined from "../components/buttonOutlined";
import MoneyIcon from '@material-ui/icons/Money';
import { ID_CARTA_MOTIVACION, ID_CENTRAL_OFFICE, SPAIN, SPAIN_TEXT } from "../constants.js";
import Loading from "../components/loading.js";
import useMaestros from "../Hooks/useMaestros.js";
import { getCandidateDocuments } from "../api/candidato.js";
import DragAndDropInput from "./DragAndDropInput.js";

const share_uri = window._env_.SHARE_BASE_URI;

const provinces = getProvincias(i18next.languages[0]);
const contractTypes = getContractTypes(i18next.languages[0]);
const minimumExperience = getMinimumExperience(i18next.languages[0]);
const salaryRange = getSalaryRange(i18next.languages[0]);
const salaryType = getSalaryType(i18next.languages[0]);
const minimunStudies = getNivelesEstudio(i18next.languages[0]);


const OFFER_STATE_PUBLISH = 2;

const isHistoryRoot = (history) => {
  if (history.length > 2) return true;
}

const useStyles = makeStyles((theme) => ({
  body_container: {
    display: "grid",
    gridArea: "body_container",
    gridTemplateAreas: "'bodyLeft separator bodyRight'",
    gridTemplateColumns: "3fr 1fr 8fr",
    gridTemplateRows: "1fr",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto auto",
      gridTemplateAreas: "'bodyLeft' 'bodyRight'",
    },
  },
  body_left_col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gridArea: "bodyLeft",
    padding: "0 20px",
  },
  body_right_col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gridArea: "bodyRight",
    padding: "0 20px",
    wordBreak: "break-word",
    paddingTop: "40px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "20px"
    },
  },
  enroll_button: {
    color: "#FFFFFF",
    height: "36px",
    textTransform: "none",
    fontWeight: "bold",
    width: "100%",
   
    [theme.breakpoints.down("sm")]: {
      marginTop: "-110px",
      marginBottom: "80px",
      fontSize: "16px",
    },
    marginBottom: "35px",
  },
  enroll_button_kq: {
    color: "#FFFFFF",
    height: "36px",
    textTransform: "none",
    fontWeight: "bold",
    width: "100%",
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      marginBottom: "30px",
      fontSize: "16px",
    },
    marginBottom: "35px",
  },
  footer_padding: {
    paddingBottom: "50px",
  },
  no_margin: {
    margin: 0,
  },
  header_logo: {
    margin: "0 0 15px 0",
    [theme.breakpoints.down("sm")]: {
      width: 200,
    },
  },
  question_title_input: {
    width: "100%",
    height: "100px",
    borderRadius: "4px",
    border: "1px solid #D8D8D8",
    resize: "none",
    fontFamily: "Nunito Sans, sans-serif",
    padding: "12px",
    marginTop: 10,
  },
  counter_chars: {
    fontSize: 12,
    color: "#a1a1a1",
  },
  no_margin_white_space: {
    margin: 0,
    whiteSpace: "pre-wrap",
  },
  register_or_login_text: {
    marginBottom: "30px",
  },
  lonely_text: {
    textAlign: "justify",
  },
  section_separator: {
    marginTop: "30px",
    whiteSpace: "pre-wrap",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      marginTop: "25px",
    },
  },
  buttons_kq: {
    display: "flex",
    marginTop: 30,
  },
  section_separator_kq: {
    marginTop: "15px",
    whiteSpace: "pre-wrap",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px",
    },
  },
  checkbox_line: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-10px",
  },
  section_title_separator: {
    marginBottom: "10px",
  },
  share: {
    marginTop: "43px",
  },
  snackBar: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
  },
  subtitle_icon: {
    float: "left",
  },
  subtitle_margin_bottom: {
    marginBottom: "10px",
  },
  subtitle_separator: {
    marginBottom: "43px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px",
    },
  },
  title: {
    marginBottom: "24px",
  },
  social_share_container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingBottom: "40px",
    [theme.breakpoints.up("md")]: {
      paddingBottom: 0,
    },
  },
  social_share: {
    display: "flex",
    width: "100%",
    marginTop: 14,
  },
  social_icon_button: {
    cursor: "pointer",
    outline: "none",
    "& + &": {
      marginLeft: 20,
    },
  },
  offer_not_found_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto",
      padding: "0 20px",
    },
  },
  offer_not_found: {
    fontSize: 25,
    fontWeight: 900,
    padding: 0,
    margin: 0,
    justifySelf: "center",
    alignSelf: "center",
  },
  layout: {
    gridTemplateRows: "minMax(auto, 1fr)",
    gridTemplateColumns: "1fr 6fr 1fr",
  },
  kq_title: {
    fontWeight: 900,
  },
  compet_list: {
    display: "grid",
    gridTemplateColumns: "1fr",
    rowGap: "20px",
    alignItems: "center",
    marginTop: "24px",
    [theme.breakpoints.down("sm")]: {
      rowGap: 0,
    },
  },
  competence: {
    margin: "0px",
    padding: "0px",
  },
  autoEvalContainer: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0",
    },
    padding: "14px 0",
  },
  compet_container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "52px",
    position: "relative",
    justifyContent: "center",
  },
  action_buttons_grid: {
    marginTop: "30px",
    width: "100%",
    display: "grid",
    gridTemplateColumns: ".25fr .5fr .25fr",
    [theme.breakpoints.down("sm")]: {
      marginTop: "70px",
      marginBottom: "50px",
      gridTemplateRows: "10px",
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
            'return'`
    },
  },
  money_Icon: {
    marginLeft: "-1px"
  },
  minimum_Experience: {
    marginTop: "20px"
  },
  minimum_Experience_text: {
    marginLeft: "145px",
    marginTop: "-23px",
    [theme.breakpoints.down("sm")]: {
      margin: 0
    }
  },
  chip: {
    marginTop: 8,
  },
  title_knowledge: {
    marginBottom: 5
  },
  separator: {
    marginRight: 10,
    marginBottom: 10
  },
  no_Space: {
    display: "none"
  },
  MotivationLetterDialog: {
    width: "536px",
  }
}));

const MAX_CHARS_QUESTION = 500;

function isChecked(key, indexQuestion, killerQuestions) {
  return killerQuestions[indexQuestion].checked === key;
}

function isAnswer(answer, question) {
  const answers = [
    "respuesta1",
    "respuesta2",
    "respuesta3",
    "respuesta4",
    "respuesta5",
  ];
  return answers.includes(answer) && question[answer];
}

const Text = ({
  fontSize,
  lineHeight,
  fontWeight,
  text,
  className,
  classes,
}) => (
  <Typography component="div" className={className}>
    <Box
      display="inline-block"
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={fontWeight ? fontWeight : ""}
      m={1}
      className={classes.no_margin_white_space}
    >
      {text}
    </Box>
  </Typography>
);

const handleSetRespuesta = (
  value,
  killerQuestions,
  indexQuestion,
  setKillerQuestions
) => {
  let tempKQ = [...killerQuestions];
  tempKQ[indexQuestion].respuesta = value;
  setKillerQuestions(tempKQ);
};

const KQSection = ({
  question,
  indexQuestion,
  classes,
  killerQuestions,
  handleCheckBoxChange,
  setKillerQuestions,
  matchesMobile,
}) => (
  <div>
    <div className={classes.section_separator_kq}>
      {
        <Text
          fontSize={matchesMobile ? 12 : 16}
          fontWeight={700}
          text={question.pregunta}
          classes={classes}
        />
      }
    </div>
    {question.idTipoPregunta === 2 ? (
      Object.keys(question).map(function (keyObj, index) {
        if (isAnswer(keyObj, question)) {
          return (
            <div className={classes.checkbox_line}>
              <Checkbox
                color="#FFFFFF"
                size="small"
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={isChecked(keyObj, indexQuestion, killerQuestions)}
                onChange={() => handleCheckBoxChange(keyObj, indexQuestion)}
              />
              {
                <div style={{ paddingBottom: 2 }}>
                  <Text
                    fontSize={14}
                    lineHeight={"20px"}
                    text={question[keyObj]}
                    classes={classes}
                  />
                </div>
              }
            </div>
          );
        }
      })
    ) : (
      <div style={{ marginRight: 10 }}>
        <textarea
          placeholder="Introduzca la respuesta"
          className={classes.question_title_input}
          value={question.respuesta}
          onChange={(e) =>
            handleSetRespuesta(
              e.target.value,
              killerQuestions,
              indexQuestion,
              setKillerQuestions
            )
          }
          maxLength={MAX_CHARS_QUESTION}
        ></textarea>
        <Typography variant="body1" className={classes.counter_chars}>
          {`${question.respuesta?.trim().length || 0
            }/${MAX_CHARS_QUESTION} (mínimo 10 caracteres)`}
        </Typography>
      </div>
    )}
  </div>
);

function OfferDetails(props) {
  const { t } = useTranslation();
  const { paises, locations, userNotAuthenticated } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maestros = useMaestros();

  const [loading, setLoading] = useState(true);
  const [killerQuestions, setKillerQuestions] = useState([]);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [offer, setOffer] = useState(null);
  const [isCandidateEnroll, setIsCandidateEnroll] = useState(true);
  const [competences, setCompetences] = useState([]);
  const [valuesCompetencias, setValuesCompetencias] = useState([]);
  const [tipoHorarioOptions, setTipoHorarioOptions] = useState([]);
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isCentralOffice, setIsCentralOffice] = useState();
  const [motivationLetter, setMotivationLetter] = useState({});
  const hasDocumentMotivationLetter = documents.find((d) => d.idTipoDocumento === ID_CARTA_MOTIVACION);

  useEffect(() => {
    handleGetOffer(props.match.params.offerId);
  }, [props.match.params.offerId]);

  useEffect(() => {
    if (!isCentralOffice && offer) setIsCentralOffice(offer?.idOficina === ID_CENTRAL_OFFICE);
    if (isCentralOffice && !userNotAuthenticated) handleGetDocuments();
  }, [offer, isCentralOffice, userNotAuthenticated]);

  useEffect(() => {
    handleGetIsCandidateEnroll();
  }, [offer]);

  useEffect(() => {
    handleGetMaestro(tipoHorarioOptions, setTipoHorarioOptions, maestros.getTipoHorario());
  }, [tipoHorarioOptions]);

  function getValueCompetition(idCompetencia) {
    let value = valuesCompetencias?.find(
      (v) => v.idCompetencia === idCompetencia
    )?.valorAutoevaluacion;
    return value;
  }

  const CompetencesSection = ({
    competences,
    setter,
    classes,
    matchesMobile,
  }) => {
    return competences.map((c, index) => (
      <div className={classes.competence}>
        <Text
          fontSize={matchesMobile ? 12 : 16}
          fontWeight={700}
          text={c.nombre}
          classes={classes}
        />
        <div className={classes.autoEvalContainer}>
          {getValueCompetition(c.id) === null ? (
            <ButtonOutlined
              text={t("offerDetails.evaluateCompetition")}
              onClick={() => setter(index, 1)}
            />
          ) : (
            <AutoevalSlider
              value={getValueCompetition(c.id)}
              marks={c.graduaciones}
              index={index}
              setter={(value) => setter(index, value)}
            />
          )}
        </div>
      </div>
    ));
  };

  async function handleGetOffer(offerId) {
    setLoading(true);
    let response = null;

    !matchesMobile && !props.match.params.preview
      ? (response = await getOffer(offerId, returnLangId(i18next.languages[0])))
      : (response = await getOfferPreview(
        offerId,
        returnLangId(i18next.languages[0])
      ));

    if (response.status === 200) {
      const result = await response.json();

      setOffer(result);
      setLoading(false);
    } else {
      props.enqueueSnackbar(t("offerDetails.general_error"), {
        variant: "error",
      });
      setOffer(null);
    }

    setLoading(false);
  }

  async function handleGetIsCandidateEnroll() {
    if (isUserAuthenticated() && offer && !props.match.params.preview) {
      const response = await isCandidateEnrollInOffer(
        returnLangId(i18next.languages[0]),
        offer?.idOferta
      );

      if (response.status === 200) {
        const result = await response.json();

        setIsCandidateEnroll(result);
      } else {
        props.enqueueSnackbar(t("offerDetails.general_error"), {
          variant: "error",
        });
      }
    }
  }

  // Esta propiedad sólo se pasa cuando el usuario NO está logueado:
  const isUserAuthenticated = () => {
    return !props.userNotAuthenticated;
  };

  function compare(a, b) {
    if (a.orden < b.orden) {
      return -1;
    }
    if (a.orden > b.orden) {
      return 1;
    }
    return 0;
  }

  async function handleKQEnroll() {
    await handleCompetences();
    const response = await getKQ(
      props.match.params.offerId,
      returnLangId(i18next.languages[0])
    );

    if (response.status === 200) {
      const result = await response.json();
      if (result.length) {
        return result.sort(compare);
      } else {
        return null;
      }
    } else {
      props.enqueueSnackbar(t("offerDetails.enroll_error"), {
        variant: "error",
      });
      return null;
    }
  }

  function handleSetValueCompetencias(index, value) {
    let aux = [...valuesCompetencias];
    aux[index] = { ...valuesCompetencias[index], valorAutoevaluacion: value };
    setValuesCompetencias([...aux]);
  }

  async function handleCompetences() {
    const response = await getCompetences(
      props.match.params.offerId,
      returnLangId(i18next.languages[0])
    );

    if (response.status === 200) {
      const result = await response.json();
      if (result.length) {
        return result;
      } else {
        return null;
      }
    } else {
      props.enqueueSnackbar(t("offerDetails.enroll_error"), {
        variant: "error",
      });
    }
    return null;
  }

  async function handleGetDocuments() {
    setLoading(true);
    const response = await getCandidateDocuments(
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      const result = await response.json();
      setDocuments(result);
    } else {
      props.enqueueSnackbar(t("my_documents.get_error"), {
        variant: "error",
      });
    }
    setLoading(false);
  }

  function hasMotivationLetter() {
      handleChecks();
  }

  async function handleChecks() {
    setLoading(true);
    setIsCandidateEnroll(true);
    const kq = await handleKQEnroll();
    const competences = await handleCompetences();
    if (isCentralOffice && !hasDocumentMotivationLetter) {
      setShowUploadDocument(true);
    }
    if (kq || competences || (isCentralOffice && !hasDocumentMotivationLetter)) {
      setKillerQuestions(kq ?? []);
      setCompetences(competences ?? []);
      setValuesCompetencias(
        competences?.map((c) => ({
          idCompetencia: c.id,
          valorAutoevaluacion: null,
        }))
      );
      setIsCandidateEnroll(false);
    } else {
      handleEnroll({});
    }
    setLoading(false);
  }

  async function handleEnroll(data) {
    setLoading(true);
    const response = await enrollOffer(
      props.match.params.offerId,
      returnLangId(i18next.languages[0]),
      data
    );

    if (response.status === 200) {
      setLoading(false);
      setIsCandidateEnroll(true);
      setKillerQuestions([]);
      setCompetences([]);
      setIsSnackBarOpen(true);
      window.gtag("event", "inscripcion", {
        id_oferta: props.match.params.offerId,
        titulo_oferta: offer.titulo,
        send_to: window._env_.GA_MEASUREMENT_ID,
      });
    } else {
      setIsCandidateEnroll(false);
      props.enqueueSnackbar(t("offerDetails.enroll_error"), {
        variant: "error",
      });
    }
    setLoading(false);
    setShowUploadDocument(false);
  }

  const handleRegisterClick = () => {
    window.gtag("event", "registro_oferta", {
      id_oferta: props.match.params.offerId,
      titulo_oferta: offer.titulo,
      send_to: window._env_.GA_MEASUREMENT_ID,
    });
    sessionStorage.setItem("origin:login", window.location.pathname);
    window.location.href = window._env_.OIDC_REGISTER_URI;
  };

  const handleLoginClick = () => {
    window.gtag("event", "login_oferta", {
      id_oferta: props.match.params.offerId,
      titulo_oferta: offer.titulo,
      send_to: window._env_.GA_MEASUREMENT_ID,
    });
    sessionStorage.setItem("origin:login", window.location.pathname);

    props.auth.login();
  };

  const Subtitle = ({ text, className, isBold, icon }) => (
    <Typography component="div" className={className}>
      <Box
        display="inline-block"
        fontSize={14}
        lineHeight={"20px"}
        fontWeight={isBold ? 900 : ""}
        m={1}
        className={classes.no_margin}
      >
        {icon}
        {text}
      </Box>
    </Typography>
  );

  function getLocationNameSpain() {
    let locationName = locations?.find(
      (location) => Number(location.id) === offer.idLocalidad
    )?.name;
    let provinceName = provinces.find(
      (prov) => Number(prov.id) === offer.idProvincia
    )?.name;
    let result = locationName && provinceName ? `${locationName}, ${provinceName}` : null;
    return result || SPAIN_TEXT;
  }

  function getLocation() {
    let provinceName = provinces.find((prov) => Number(prov.id) === offer.idProvincia)?.name;
    let country = paises?.find((pais) => Number(pais.id) === offer.idPais)?.nombre;
    let region = provinceName !== "" ? `, ${provinceName}` : "";
    let city = offer.ciudad ? `, ${offer.ciudad}` : "";
    return `${country}${region}${city}`;
  }

  function getSalaryRangeResult() {
    const hasSalary = offer.idTipoSalario && offer.idSalarioDesde && offer.idSalarioHasta;
    if(offer.ocultarSalario || !hasSalary){
      return t("offerDetails.no_avaliable_salary");
    }

    let salaryFrom = salaryRange.find(
      (salaryRanges) => Number(salaryRanges.id) === offer.idSalarioDesde
    )?.name;
    let salaryTo = salaryRange.find(
      (salaryRanges) => Number(salaryRanges.id) === offer.idSalarioHasta
    )?.name;
    let salary_Type = salaryType.find(
      (salaryTypes) => Number(salaryTypes.id) === offer.idTipoSalario
    )?.name;

    return `${salaryFrom} - ${salaryTo} ${salary_Type}`;
  }

  function getGeneralRequirements() {
    let experienciaMinima = minimumExperience.find(
      (minimumExperiences) => Number(minimumExperiences.id) === offer.idExperienciaMinima
    )?.name;

    return `${experienciaMinima}`;
  }

  function getStudies() {
    let minStudies = minimunStudies.find(
      (studies) => Number(studies.id) === offer.idFormacion
    )?.name;
    return `${minStudies}`;
  }

  const BasicData = () => (
    <>
      <Text
        fontSize={matchesMobile ? 21 : 36}
        lineHeight={matchesMobile ? "26px" : "46px"}
        fontWeight={900}
        text={offer.titulo}
        className={classes.title}
        classes={classes}
      />
      <Subtitle
        text={offer.idPais == SPAIN ?
          <div style={{ display: "flex" }}>
            {getLocationNameSpain() ?? <Loading height={20} width={20} />}
          </div>
          : getLocation()}
        className={classes.subtitle_margin_bottom}
        icon={
          <Icon color="primary" className={classes.subtitle_icon}>
            <Location title={t(`icons_tooltips.location`)} />
          </Icon>
        }
      />
      {tipoHorarioOptions.find(
        (workday) => Number(workday.id) === offer.idTipoHorario
      )?.name ? <Subtitle
        text={
          tipoHorarioOptions.find(
            (workday) => Number(workday.id) === offer.idTipoHorario
          )?.name ?? ""
        }
        className={classes.subtitle_margin_bottom}
        icon={
          <Icon color="primary" className={classes.subtitle_icon}>
            <TimeClock title={t(`icons_tooltips.schedule_type`)} />
          </Icon>
        }
      /> : null}
      <Subtitle
        text={
          contractTypes.find(
            (contractType) => Number(contractType.id) === offer.idTipoContrato
          )?.name ?? ""
        }
        className={`${classes.subtitle_margin_bottom}`}
        icon={
          <Icon color="primary" className={classes.subtitle_icon}>
            <Sign title={t(`icons_tooltips.contract_type`)} />
          </Icon>
        }
      />
      <Subtitle
        text={getSalaryRangeResult()}
        className={`${classes.subtitle_margin_bottom} ${classes.subtitle_separator}`}
        icon={
          <Icon className={classes.subtitle_icon} title={t(`icons_tooltips.salary`)}>
            <MoneyIcon className={classes.money_Icon} fontSize="small" />
          </Icon>
        }
      />
      <Subtitle text={offer.sector?.nombre} isBold={true} />
      <Subtitle text={offer.puesto?.nombre} isBold={true} />
      <Subtitle
        text={`${offer.numeroVacantes} ${t("offerDetails.vacancies")}`}
        className={classes.subtitle_separator}
        isBold={true}
      />
    </>
  );

  const Section = ({ header, body }) => (
    <div className={classes.section_separator}>
      <Text
        fontSize={matchesMobile ? 18 : 22}
        lineHeight={matchesMobile ? "28px" : "30px"}
        fontWeight={900}
        text={header}
        classes={classes}
        className={classes.section_title_separator}
      />
      <Text fontSize={14} classes={classes} lineHeight={"20px"} text={body} />
    </div>
  );

  const handleCheckBoxChange = (key, indexQuestion) => {
    let tempKQ = [...killerQuestions];
    tempKQ[indexQuestion].checked = key;
    setKillerQuestions(tempKQ);
  };

  function handleCancel() {
    setKillerQuestions([]);
    setCompetences([]);
    setShowUploadDocument(false);
  }

  function processData() {
    const incompleteData = killerQuestions.find(
      (q) => !q.checked && !q.respuesta
    );
    const incompleteTextArea = killerQuestions.find(
      (q) => !q.checked && q.respuesta && q.respuesta.trim().length < 10
    );
    if (incompleteData) {
      props.enqueueSnackbar(t("offerDetails.incomplete_data"), {
        variant: "error",
      });
    } else if (incompleteTextArea) {
      props.enqueueSnackbar(t("offerDetails.incomplete_textarea"), {
        variant: "error",
      });
    }else if(showUploadDocument && !Object.keys(motivationLetter).length){
      props.enqueueSnackbar(t("offerDetails.error_motivation_letter"), {
        variant: "error",
      });
    }else {
      let dataFormatted = [];

      killerQuestions.map((q) => {
        let valueKey = q.checked + "Valor";
        let obj = {
          pregunta: q.pregunta || "",
          orden: q.orden,
          respuesta: q.idTipoPregunta === 2 ? q[q.checked] || "" : q.respuesta,
          valor: q.idTipoPregunta === 2 ? q[valueKey] : null,
        };
        dataFormatted.push(obj);
      });
      handleEnroll({
        respuestasKQ: dataFormatted,
        evaluacionesCompetencias: valuesCompetencias,
      });
    }
  }

  const Enroll = () => (
    <>
      {isUserAuthenticated() && !killerQuestions.length ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.enroll_button}
          disabled={isCandidateEnroll}
          onClick={() => hasMotivationLetter()}
        >
          {t("offerDetails.enroll")}
        </Button>
      ) : null}

      {!isUserAuthenticated() ? (
        <Typography component="div" className={classes.register_or_login_text}>
          <Box
            display="inline-block"
            fontSize={14}
            lineHeight="21px"
            fontWeight={900}
            m={1}
            className={classes.no_margin}
          >
            <Link href="#" onClick={handleLoginClick}>
              {t("offerDetails.login")}
            </Link>
            {t("offerDetails.or")}
            <Link href="#" onClick={handleRegisterClick}>
              {t("offerDetails.register")}
            </Link>
            {t("offerDetails.enroll_this_offer")}
          </Box>
        </Typography>
      ) : null}
      <img
        alt="Nortempo"
        src={"/images/nortempo_logo.svg"}
        className={classes.header_logo}
      />
      <div className={classes.social_share_container}>
        <Typography variant="body1">{t("offerDetails.share_title")}</Typography>
        <div className={classes.social_share}>
          <LinkedinShareButton
            url={`${share_uri}${window.location.pathname}`}
            className={classes.social_icon_button}
          >
            <SocialIcon network="linkedin" />
          </LinkedinShareButton>
          <FacebookShareButton
            url={`${share_uri}${window.location.pathname}`}
            quote={`En @Nortempo seleccionan ${offer.titulo} en ${locations?.find(
              (l) =>
                l.id.toString().trim() === offer?.idLocalidad?.toString().trim()
            )?.name ?? ""
              }`} // Texto a mostrar por defecto en el post
            hashtag={"#túmueveselmundo"} //Hashtag que se quiere mostrar asociado al post
            className={classes.social_icon_button}
          >
            <SocialIcon network="facebook" />
          </FacebookShareButton>
          <TwitterShareButton
            url={`${share_uri}${window.location.pathname}`}
            title={`Seleccionamos ${offer.titulo} en ${locations?.find(
              (l) =>
                l.id.toString().trim() === offer?.idLocalidad?.toString().trim()
            )?.name ?? ""
              }`} // Title of the share page
            via={"Nortempo"} // it can be used to attribute the authority of the tweet if relates is used this makes no sense?
            hashtags={["túmueveselmundo", "trabajo", "empleo"]} //array of hastaghs
            className={classes.social_icon_button}
          >
            <SocialIcon network="twitter" />
          </TwitterShareButton>
          <EmailShareButton
            url={`${share_uri}${window.location.pathname}`}
            subject={"Esta oferta de empleo te interesa"} //Subject of the mail
            body={`Echa un vistazo a esta oferta ${offer.titulo
              } de Nortempo en ${locations?.find(
                (l) =>
                  l.id.toString().trim() === offer?.idLocalidad?.toString().trim()
              )?.name ?? ""
              }`} //body of the mail (the url of the offer is appended after, with the separator)
            separator={" : "} //Separator to use between body and url to share
            className={classes.social_icon_button}
          >
            <SocialIcon network="email" />
          </EmailShareButton>
          <WhatsappShareButton
            url={`${share_uri}${window.location.pathname}`}
            title={"Echa un vistazo a esta oferta de empleo de Nortempo"} //Title of the shared page
            separator={" : "} // Separator between title and url
            className={classes.social_icon_button}
          >
            <SocialIcon network="whatsapp" />
          </WhatsappShareButton>
        </div>
      </div>
    </>
  );

  const OfferNotFound = () => {
    if (loading === false) {
      return (
        <>
          <div />
          <div className={classes.offer_not_found_container}>
            <p className={classes.offer_not_found}>
              {t("offerDetails.info_offer_not_found")}
            </p>
          </div>
          <div />
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Layout
      history={props.history}
      loading={loading}
      isLogged={!userNotAuthenticated}
      className={classes.layout}
    >
      <Snackbar
        text={t("offerDetails.enroll_success", {
          search: "",
        })}
        isSnackBarOpen={isSnackBarOpen}
        handleIsSnackBarOpen={setIsSnackBarOpen}
        indeed={true}
      />
      {offer ? (
        <>
          <div />
          <div className={classes.body_container}>
            <div className={classes.body_left_col}>
              <BasicData />

              {!matchesMobile &&
                offer.idEstadoOferta === OFFER_STATE_PUBLISH &&
                !props.match.params.preview ? (
                <Enroll />
              ) : null}
              {!matchesMobile ? (
                <div className={classes.footer_padding}></div>
              ) : null}
            </div>

            {killerQuestions.length || competences.length || (showUploadDocument) ? (
              <div className={classes.body_right_col}>
                <Text
                  fontSize={20}
                  classes={classes}
                  text={killerQuestions.length ? t("offerDetails.kq_title"): ""}
                  className={classes.kq_title}
                />
                {killerQuestions.map((q, index) => {
                  return (
                    <KQSection
                      question={q}
                      indexQuestion={index}
                      matchesMobile={matchesMobile}
                      classes={classes}
                      handleCheckBoxChange={handleCheckBoxChange}
                      killerQuestions={killerQuestions}
                      setKillerQuestions={setKillerQuestions}
                    />
                  );
                })}
                {competences?.length ?
                  <div className={classes.compet_container}>
                    <Text
                      fontSize={20}
                      classes={classes}
                      text={t("offerDetails.competences_title")}
                      className={classes.kq_title}
                    />
                    <div className={classes.compet_list}>
                      <CompetencesSection
                        competences={competences}
                        setter={handleSetValueCompetencias}
                        classes={classes}
                        matchesMobile={matchesMobile}
                      />
                    </div>
                  </div> : null
                }
                {showUploadDocument &&
                  <div style={{marginTop: competences?.length || killerQuestions?.length ? "10px" : "0px"}}>
                    <Typography style={{ fontWeight: 700, marginBottom: '10px' }}>
                      {t("offerDetails.attach_Motivacion_Letter")}
                    </Typography>
                    <DragAndDropInput
                      files={motivationLetter}
                      enqueueSnackbar={props.enqueueSnackbar}
                      setFiles={setMotivationLetter}
                      idTipoDocumento={ID_CARTA_MOTIVACION} />
                  </div>
                }
                <div className={classes.buttons_kq}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                    className={classes.enroll_button_kq}
                    onClick={() => handleCancel()}
                  >
                    {t("offerDetails.cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#5D5A5A",
                      color: "white",
                    }}
                    className={classes.enroll_button_kq}
                    onClick={() => processData()}
                  >
                    {t("offerDetails.enroll")}
                  </Button>
                </div>
              </div>
            ) : (
              <div className={classes.body_right_col}>
                <Text
                  fontSize={14}
                  classes={classes}
                  lineHeight={"20px"}
                  text={offer.descripcion}
                  className={classes.lonely_text}
                />
                <Section
                  header={t("offerDetails.fundamental")}
                  body={offer.requisitos}
                />
                <div className={classes.minimum_Experience}>
                 {offer.idFormacion &&
                  <div className={classes.experience_label}>
                    <Subtitle text={`${t("offerDetails.minimumStudies")}`}
                      isBold={true}
                    />
                    <Subtitle text={`${getStudies()}`}
                      isBold={false}
                      className={classes.minimum_Experience_text}
                    />
                  </div>
                }
                {offer.idExperienciaMinima &&
                  <div className={classes.experience_label}>
                    <Subtitle text={`${t("offerDetails.minimumExperience")}`}
                      isBold={true}
                    />
                    <Subtitle text={`${getGeneralRequirements()}`}
                      isBold={false}
                      className={classes.minimum_Experience_text}
                    />
                  </div>
                }
                </div>
                <div className={offer.conocimientos?.length ? classes.chip : classes.no_Space}>
                  {offer.conocimientos?.length &&
                    <Subtitle text={t("offerDetails.necessary_Knowledge")}
                      isBold={true}
                      className={classes.title_knowledge}
                    />
                  }
                  {offer.conocimientos?.map((c) => (
                    <Chip
                      key={c}
                      label={c}
                      className={classes.separator}
                    />
                  ))}
                </div>
  
                <Section
                  header={t("offerDetails.isOffered")}
                  body={offer.seOfrece}
                />
                {isHistoryRoot(props.history) ? (
                  <div className={classes.action_buttons_grid}>
                    <div style={{ gridArea: "return", width: "100%" }}>
                      <ButtonOutlined
                        size="100%"
                        text={t("common.return")}
                        onClick={() => {
                          props.history.goBack();
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {
                  !matchesMobile ? (
                    <div className={classes.footer_padding}></div>
                  ) : null /* Eliminar cuando se meta el footer*/
                }
                {matchesMobile &&
                  offer.idEstadoOferta === OFFER_STATE_PUBLISH &&
                  !props.match.params.preview ? (
                  <Enroll />
                ) : null}
              </div>
            )}
          </div>
          <div />
        </>
      ) : (
        OfferNotFound()
      )}
    </Layout>
  );
}

export default withSnackbar(OfferDetails);
